import {Task} from '../task/task.model';

export class Column {
  title: string;
  tasks: Task[] = [];
  addTaskFormVisible = false;
  isTitleEditable = false;

  constructor(title: string, tasks: Task[] = []) {
    this.title = title;
    this.tasks = tasks;
  }
}
