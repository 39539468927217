export class Task {
  title: string;
  text: string;
  isEditable = false;
  isExpanded = false;

  constructor(title: string = '', text: string = '') {
    this.title = title;
    this.text = text;
  }
}
