import {Injectable} from '@angular/core';
import {Board} from '../board/board.model';
import {Task} from './task.model';

@Injectable({
  providedIn: 'root',
})

export class TaskService {

  addTask(board: Board, columnIndex: number, task: Task): Board {
    board.columns[columnIndex].addTaskFormVisible = false;
    if (task.title !== '' || task.text !== '') {
      board.columns[columnIndex].tasks.push(new Task(task.title, task.text));
    }

    return board;
  }

  removeTask(board: Board, columnIndex: number, taskIndex: number): Board {
    board.columns[columnIndex].tasks.splice(taskIndex, 1);

    return board;
  }

  updateTask(board: Board, columnIndex: number, taskIndex: number, task: Task): Board {
    board.columns[columnIndex].tasks[taskIndex] = task;

    return board;
  }
}
