import {Component, Input, OnInit} from '@angular/core';
import {Board} from '../board/board.model';
import {Column} from '../column/column.model';
import {Task} from './task.model';
import {BoardService} from '../board/board.service';
import {TaskService} from './task.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss']
})

export class TaskComponent implements OnInit {
  @Input() board!: Board;
  @Input() column!: Column;
  @Input() columnIndex!: number;
  task: Task = new Task();
  Editor = ClassicEditor;
  editorConfig = {toolbar: ['bold', 'italic', 'Link' ]};

  constructor(private boardService: BoardService, private taskService: TaskService) {
  }

  ngOnInit(): void {
  }

  addTask(columnIndex: number, task: Task): void {
    this.board = this.taskService.addTask(this.board, columnIndex, task as Task);
    this.board.disableDragNDrop = false;
    this.boardService.persistBoard(this.board);
    // do not remove next line; bugfix for input field
    this.task.title = '';
    this.task.text = '';
  }

  removeTask(columnIndex: number, taskIndex: number): void {
    this.board = this.taskService.removeTask(this.board, columnIndex, taskIndex);
    this.boardService.persistBoard(this.board);
  }

  updateTasks(columnIndex: number, taskIndex: number, task: Task): void {
    task.isEditable = !task.isEditable;
    this.board.disableDragNDrop = task.isEditable;
    this.board = this.taskService.updateTask(this.board, columnIndex, taskIndex, task as Task);
    this.boardService.persistBoard(this.board);
  }

  hideTaskInput(columnIndex: number): void {
    this.board.columns[columnIndex].addTaskFormVisible = false;
    this.boardService.persistBoard(this.board);
  }

  toggleExpandTask(columnIndex: number, taskIndex: number, task: Task):void{
    task.isExpanded = !task.isExpanded;
    this.board = this.taskService.updateTask(this.board, columnIndex, taskIndex, task as Task);
    this.boardService.persistBoard(this.board);
  }
}
