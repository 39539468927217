import { Component, OnDestroy } from '@angular/core';
import { interval, observable, Observable, Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnDestroy {
  timerObject: Subscription | undefined
  timeInSeconds: number = 0;
  running: boolean = false;
  startText = 'Timer starten';

  constructor() { }

  startTimer() {
    this.running = !this.running;
    if (this.running) {
      this.startText = 'Timer beenden';
      this.timerObject = timer(0,1000).subscribe(timeInSeconds => this.timeInSeconds = timeInSeconds);
    }else{
      this.clearTimer();
    }
  }

  clearTimer() {
    this.running = false;
    this.timeInSeconds = 0;
    this.startText = 'Timer starten';
    this.timerObject?.unsubscribe();
  }

  ngOnDestroy() {
    this.timerObject?.unsubscribe();
  }
}
