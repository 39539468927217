import {Injectable} from '@angular/core';
import {Board} from '../board/board.model';
import {Column} from './column.model';

@Injectable({
  providedIn: 'root',
})

export class ColumnService {

  updateColumnEditability(board: Board, columnIndex: number, column: Column): Board {
    board.columns[columnIndex].isTitleEditable = column.isTitleEditable;

    return board;
  }

  updateColumnTitle(board: Board, columnIndex: number, newColumnTitle: string): Board {
    board.columns[columnIndex].title = newColumnTitle;
    board.columns[columnIndex].isTitleEditable = false;

    return board;
  }

  addColumn(board: Board, column: Column): Board {
    board.columns.push(column);

    return board;
  }

  removeColumn(board: Board, columnIndex: number): Board {
    board.columns.splice(columnIndex, 1);

    return board;
  }
}
