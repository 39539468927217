import {Column} from '../column/column.model';

export class Board {
  public title: string;
  public backgroundImage = '';
  public disableDragNDrop = false
  public columns: Column[] = [
    new Column('Spalte-1'),
    new Column('Spalte-2'),
    new Column('Spalte-3'),
  ];

  constructor(title: string) {
    this.title = title;
  }
}
