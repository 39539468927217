import {Injectable} from '@angular/core';
import {Board} from './board.model';


@Injectable({
  providedIn: 'root',
})

export class BoardService {

  public checkIfBoardExists(): boolean {
    return this.getBoardJson() !== null;
  }

  public getBoardJson(): any {
    return localStorage.getItem('board');
  }

  public getBoard(): any {
    return JSON.parse(this.getBoardJson() as string);
  }

  public getSavedBoard(): any {
    return this.getBoard();
  }

  changeBoardTitle(newBoardTitle: string): Board {
    const board = this.getBoard();
    board.title = newBoardTitle;
    this.persistBoard(board);

    return board;
  }

  persistBoard(board: Board): void {
    localStorage.setItem('board', JSON.stringify(board));
  }
}
