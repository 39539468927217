import {Component, OnInit} from '@angular/core';
import {Board} from './board.model';
import {Column} from '../column/column.model';
import {BoardService} from './board.service';
import {ColumnService} from '../column/column.service';
import {formatDate} from '@angular/common';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.scss']
})

export class BoardComponent implements OnInit {
  public board!: Board;
  public renameBoard = false;
  public isMobile = false;
  private selectedFileForUpload!: File;
  private selectedBackgroundFile!: File;
  public allTasksCollapsed = true;

  constructor(private boardService: BoardService, private columnService: ColumnService) {
  }

  ngOnInit(): void {
    this.checkIfMobile();
    this.initDefaultBoardsIfNoneExists();
    this.board = this.boardService.getSavedBoard();
    this.initBackgroundIfExists();
    this.checkIfAtLeastOneTaskIsExpanded();
  }


  checkIfAtLeastOneTaskIsExpanded(): void{
    this.board.columns.forEach(column => {
      column.tasks.forEach(task => {
        if(task.isExpanded){
          this.allTasksCollapsed = false;
        }
      });
    });
  }

  toggleBoardRenaming(): void {
    this.renameBoard = !this.renameBoard;
    if (this.renameBoard) {
      setTimeout(() => {
        document.getElementById('boardTitle')?.focus();
      }, 0);
    }
  }

  changeBoardTitle(newBoardTitle: string): void {
    this.board = this.boardService.changeBoardTitle(newBoardTitle);
    this.toggleBoardRenaming();
  }

  export(): void {
    const fileName = this.board.title + '_' + formatDate(new Date(), 'd.MM.YYYY', 'en-DE') + '.json';
    const element = document.createElement('a');
    const event = new MouseEvent('click');
    element.setAttribute('href', `data:text/json;charset=utf-8,${encodeURIComponent(this.boardService.getBoardJson())}`);
    element.setAttribute('download', fileName);
    element.dispatchEvent(event);
  }

  import(event: any): void {
    this.selectedFileForUpload = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsText(this.selectedFileForUpload, 'UTF-8');
    fileReader.onload = () => {
      try {
        JSON.parse(fileReader.result as string);
        const confirmImport = confirm('Datei importieren? Das aktuelle Board wird überschrieben.');
        if (confirmImport) {
          localStorage.setItem('board', fileReader.result as string);
          window.location.reload();
        }
      } catch (e) {
        alert('Fehlerhafter Datei-Inhalt');
      }
    };
  }

  setBackgroundOfApp(url: string): void {
    const htmlRootElement = document.getElementsByTagName('html')[0];
    htmlRootElement.style.backgroundImage = 'url(' + url + ')';
    htmlRootElement.style.backgroundSize = 'cover';
  }

  onBackgroundUpload(event: any): void {
    const fileReader = new FileReader();
    this.selectedBackgroundFile = event.target.files[0];
    fileReader.addEventListener('load', () => {
      this.board.backgroundImage = fileReader.result as string;
      this.boardService.persistBoard(this.board);
      this.setBackgroundOfApp(fileReader.result as string);
    }, false);

    if (this.selectedBackgroundFile) {
      fileReader.readAsDataURL(this.selectedBackgroundFile);
    }
  }

  addColumn(): void {
    this.board = this.columnService.addColumn(this.board, new Column('Spalte-' + (this.board.columns.length + 1)));
    this.boardService.persistBoard(this.board);
  }


  expandAll(): void {
    this.allTasksCollapsed = !this.allTasksCollapsed;
    this.board.columns.forEach(column => {
      column.tasks.forEach(task => {
        task.isExpanded = true;
      });
    });

    this.boardService.persistBoard(this.board);
  }

  collapseAll(): void {
    this.allTasksCollapsed = !this.allTasksCollapsed;
    this.board.columns.forEach(column => {
      column.tasks.forEach(task => {
        task.isExpanded = false;
      });
    });

    this.boardService.persistBoard(this.board);
  }

  private checkIfMobile(): void {
    this.isMobile = window.screen.width <= 768;
  }

  private initDefaultBoardsIfNoneExists(): void {
    if (this.boardService.checkIfBoardExists()) {
      this.board = this.boardService.getBoard();
    } else {
      this.board = new Board('Default');
      this.boardService.persistBoard(this.board);
    }
  }

  private initBackgroundIfExists(): void {
    if (this.board.backgroundImage !== '') {
      this.setBackgroundOfApp(this.board.backgroundImage);
    }
  }

  dropColumn(event: CdkDragDrop<any>): void {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    this.board.columns = event.container.data;
    this.boardService.persistBoard(this.board);
    window.location.reload();
  }
}