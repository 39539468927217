import {Component, Input, OnInit} from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {Board} from '../board/board.model';
import {Column} from './column.model';
import {Task} from '../task/task.model';
import {BoardService} from '../board/board.service';
import {ColumnService} from './column.service';

@Component({
  selector: 'app-column',
  templateUrl: './column.component.html',
  styleUrls: ['./column.component.scss']
})

export class ColumnComponent implements OnInit {
  @Input() board!: Board;

  constructor(private boardService: BoardService, private columnService: ColumnService) {
  }

  ngOnInit(): void {
  }

  changeColumnTitle(columnIndex: number, newColumnTitle: string): void {
    this.board = this.columnService.updateColumnTitle(this.board, columnIndex, newColumnTitle);
    this.boardService.persistBoard(this.board);
  }

  toggleColumnRenaming(columnIndex: number, column: Column): void {
    column.isTitleEditable = !column.isTitleEditable;
    this.board = this.columnService.updateColumnEditability(this.board, columnIndex, column as Column);
    this.boardService.persistBoard(this.board);
    if (column.isTitleEditable) {
      setTimeout(() => { // this will make the execution after the above boolean has changed
        document.getElementById(column.title)?.focus();
      }, 0);
    }
  }

  drop(event: CdkDragDrop<Task[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      // just to let the everyone know that i definitely want to convert string to number here.
      const currentColumnId = event.container.id.split('-').slice(-1)[0] as unknown as number;
      this.updateTasksOfColumn(currentColumnId, event.container.data);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
      const currentColumnId = event.container.id.split('-').slice(-1)[0] as unknown as number;
      const previousColumnId = event.previousContainer.id.split('-').slice(-1)[0] as unknown as number;
      this.updateTasksOfColumn(currentColumnId, event.container.data);
      this.updateTasksOfColumn(previousColumnId, event.previousContainer.data);
    }
  }

  removeColumn(columnIndex: number): void {
    const confirmRemove = confirm('Möchtest du wirklich die gesamte Spalte ' + this.board.columns[columnIndex].title + ' löschen?');
    if (confirmRemove) {
      this.board = this.columnService.removeColumn(this.board, columnIndex);
      this.boardService.persistBoard(this.board);
    }
  }

  showTaskInput(columnIndex: number): void {
    this.board.columns[columnIndex].addTaskFormVisible = true;
    this.board.disableDragNDrop = true;
    this.boardService.persistBoard(this.board);
    setTimeout(() => { // this will make the execution after the above boolean has changed
      document.getElementById('new-task-' + columnIndex)?.focus();
    }, 0);
  }

  private updateTasksOfColumn(columnIndex: number, tasks: Task[]): void {
    this.board.columns[columnIndex-1].tasks = tasks;
    this.boardService.persistBoard(this.board);
  }
}
